 <div class="progress-bar-stepper d-flex justify-content-center w-100 gap-20 my-4">
    <ng-container *ngFor="let step of stepperLayout; let i = index">
      <div class="stage-1 d-flex flex-column align-items-center" (click)="setActiveIndex(i,true)">
          <span 
          class="d-flex justify-content-center step-name p-text font-weight-6 font-primary text-14"
          [ngClass]="{'first-step': i === 0 || i === 2 , 'active-step': step.isFormValid, 'inactive-step': !step.isFormValid,'center-align':i == 1}">
          {{ step.name }}
        </span>
        <div class="line mt-2" [ngClass]="{'active': step.isFormValid}"></div>
      </div>
    </ng-container>
  </div>  
  