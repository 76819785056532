import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UtilService } from '../../../../core/services/util.service';

@Component({
  selector: 'stepper',
  templateUrl: './stepper.component.html',
  styleUrl: './stepper.component.scss'
})
export class StepperComponent {
  @Input() stepperType!: any;
  @Input() stepperLayout!: any;
  @Output() layoutChange = new EventEmitter;
  completed: any = false;
  translateY!: any;
  previousIndex: any = 0;
  currentStep = 0;
  stepWidth = 170;
  constructor(
    public _util: UtilService
  ) {}
  
  ngOnInit() {
    this._util.getSidebarListMaxIndex().subscribe((listIndex) => {
      this._util.sidebarMaxIndex = listIndex;
      this.setActiveIndex(listIndex);
      if (listIndex) this.sidebarAnimation(listIndex);
    });
    this._util.getActiveListIndex().subscribe((index) => {
      this._util.activeListItemIndex = index;
      if (this.stepperType === 'vertical') {
        this.sidebarAnimation(index);
      }
    });
  }
  ngOnDestroy() {
    this._util.sidebarListIndex.next(0);
  }
  setActiveIndex(index: number, isSidebarClick?: boolean) {
    // this._util.setActiveListIndex(index);
    // if (index <= this._util.sidebarMaxIndex) {
      // if(this.stepperType === 'vertical') {
        // this.sidebarAnimation(index);
      // }
      // this.layoutChange.emit(index);
    // }
  }
  sidebarAnimation(index: number, isSidebarClick?: boolean) {
    this._util
      .getPreviousIndex()
      .subscribe((preIndex) => (this.previousIndex = preIndex));
    if (index !== this.previousIndex) {
      this.completed = true;
      let translate = 54;
      translate = index * translate;
      this.translateY = `${translate}px`;
      this._util.setPreviousIndex(index);
    }
  }
  checkFormValidity(step: any, index: any) {
    if(this.stepperLayout[index].isFormValid || index <= this._util.sidebarMaxIndex) {
      return true;
    }
    return false;
  }

  isStepCompleted(stepIndex: number): boolean {
    return this.stepperLayout[stepIndex].isFormValid === 1;
  }
}
