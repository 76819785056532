<div class="confirmation-popup-container w-px-500">
    <div class="popup-close-icon position-absolute cursor-pointer" (click)="cancelPopup()">
        <img class="h-px-18" src="assets/icons/gray-close-icon.svg">
    </div>
    <ng-container  *ngIf="!data?.warn">
        <div class="d-flex justify-content-center">
            <img src="assets/icons/done.svg" class="w-10" alt="">
        </div>
    </ng-container>
    <ng-container  *ngIf="data?.warn">
        <div class="d-flex justify-content-center">
            <img src="assets/icons/info_round_yellow.svg" class="w-10" alt="">
        </div>
    </ng-container>
    <div class="text-17 font-weight-6 d-flex justify-content-center">{{ data?.tag }}</div>
    <ng-container *ngIf="data?.content">
        <div class="mt-2  text-15 color-text-gray-70 d-flex justify-content-center  align-items-center text-center ">{{ data?.content }}</div>
        <div class="mt-2 mb-4 text-15 color-text-gray-70 d-flex justify-content-center align-items-center">{{ data?.reference }} <span class="text-15 font-weight-6 ps-1">{{data?.referenceNumber}}</span></div>  
    </ng-container>
    <div class="d-flex align-items-center justify-content-center gap-10">
        <!-- <button mat-flat-button class="empty-border-button bg-transparent w-auto h-px-32 rounded-9" (click)="cancelPopup()">
            <span class="font-weight-4 color-tertiary text-15">Cancel</span>
        </button> -->
        <button mat-flat-button class="nex-button-anxn rounded-8 bg-primary color-white h-100 w-px-100 w-md-25 p-2 font-secondary" (click)="confirmPopup()">
            <span class="font-weight-4 text-15">OK</span>
        </button>
    </div>
</div>