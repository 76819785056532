import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GoogleMapsModule } from '@angular/google-maps';
import { FilePickerModule } from 'ngx-awesome-uploader';
import { NgxDocViewerModule } from 'ngx-doc-viewer';

const export_modules = [
  NgbModule,
  GoogleMapsModule,
  FilePickerModule,
  NgxDocViewerModule
]

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ...export_modules
  ],
  exports: [
    ...export_modules
  ]
})
export class PackagesModule { }
