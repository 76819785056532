<mat-form-field appearance="outline" class="full-width box-input {{ customClass }}">
    <mat-label> {{label}} </mat-label>
    <input matInput 
        [matDatepicker]="picker"
        [matDatepickerFilter]="weekendFilter"  
        (click)="dateSelector && picker.open()" 
        [min]="minDate" 
        [max]="maxDate" 
        placeholder="{{placeHolder}}" 
        [formControl]="control"
        (dateChange)="onchange($event)"
        [required]="required"
        [readonly]="readonly"
       >
       <!-- [disabled]="disabled" -->
       <!-- [ngClass]="{'grey-out-form-label': disabled}" -->
    <mat-datepicker-toggle matSuffix [for]="picker" *ngIf="pickerDisabled">
        <mat-icon matDatepickerToggleIcon>
            <img src="assets/icons/calendar_svg.svg" class="calendar_img" />
        </mat-icon>
    </mat-datepicker-toggle>
    <img *ngIf="!pickerDisabled" src="/assets/images/icons/read-onely.svg" class="mal--13 {{disabledClass}}" />
    <mat-datepicker [dateClass]="dateClass" #picker></mat-datepicker>
    <mat-error *ngIf="control?.touched && control?.hasError('required')">This field is required</mat-error>
    <mat-error *ngIf="control?.hasError('customFieldValidationError')"> {{ control?.errors?.['customFieldValidationError'] }}</mat-error> 
    <mat-error *ngIf="control?.hasError('customError')"> {{ control?.errors?.['customError'] }}</mat-error> 
    <mat-error *ngIf="control?.hasError('matDatepickerMax')">{{maxError}}</mat-error>
    <mat-error *ngIf="control?.hasError('matDatepickerMin')">{{minError}}</mat-error>
</mat-form-field>
