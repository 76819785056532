import { LiveAnnouncer } from '@angular/cdk/a11y';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  forwardRef,
  inject,
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { UtilService } from '../../../../core/services/util.service';


@Component({
  selector: 'multiselect',
  templateUrl: './multiselect.component.html',
  styleUrl: './multiselect.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => MultiselectComponent),
    },
  ],
})
export class MultiselectComponent  implements OnInit,OnChanges {
  @Input() items: any;
  @Input() placeHolder: any;
  @Input() label: any;
  @Input() required: boolean = false;
  @Input() control: any = new FormControl();
  @Input() localSearch: boolean = false;
  @Input() key_name: string = 'employee';
  @Input() liveSearch: boolean = false;
  @Input() action: string = '';
  @Input() inputClass:string= '';
  showDropdown: boolean = false;
  @Input() chipClass!: string;
  @Input() customClass!: string;
  @Output() onValueChange = new EventEmitter<any>();
  @Output() onScrollDropdown = new EventEmitter();
  @Output() onFocusOut: EventEmitter<any> = new EventEmitter<any>();
  @Output() onValueSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() liveSearchValue: EventEmitter<any> = new EventEmitter();
  @Output() newValue: EventEmitter<any> = new EventEmitter();
  @Output() localSearchValue: EventEmitter<any> = new EventEmitter();
  separatorKeysCodes: number[] = [ENTER, COMMA];
  @Input() selectedItems: any[] = [];
  filteredItems: any[] = [];
  @ViewChild('input') input!: ElementRef;
  searchLength: number = 0;
  announcer = inject(LiveAnnouncer);
  constructor(public _util: UtilService) { }
  ngOnInit(): void {
    this.filteredItems = this.items;
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.filteredItems = this.items;
    // this.filteredItems?.map((item: any) => {
      // item.disable = this.selectedItems?.some((selectedItem: any) => selectedItem.id === item.id)
    // });
  }
  onChange = (data: any) => { };
  onTouch = (_: any) => { };
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  writeValue(value: any) {
    this.onChange(value);
  }
  remove(option: string): void {
    this.input.nativeElement.value = '';
    if (this.input.nativeElement.value = '') {
      this.showDropdown = false;
    }
    const index = this.selectedItems.indexOf(option);
    if (index >= 0) {
      this.selectedItems.splice(index, 1);
      // this.filteredItems[index].disable = false;
      this.onChange(this.selectedItems); // Emit the updated selected items
      this.announcer.announce(`Removed ${option}`);
    }
    // this.filteredItems?.forEach((item: any) => {
      // if (this.selectedItems?.some((selectedItem: any) => selectedItem.id === item.id)) {
        // item.disable = true;
      // } else {
        // item.disable = false;
      // }
      // return;
    // });
  }
  selected(event: MatAutocompleteSelectedEvent, value: any): void {
    const item = event.option.value; 
    if (!this.selectedItems.includes(item)) {
      this.selectedItems.push(item);
      this.onValueSelected.emit(this.selectedItems);
      this.onChange(this.selectedItems);
    }
    this.input.nativeElement.value = '';
    this.showDropdown = false;
  }
  onSearch(event: any, key: string) {
    const value = event.value;
    this.searchLength = event.value.length
    if (this.searchLength > 0) {
      this.showDropdown = true;
    } else {
      this.showDropdown = false;
      this.searchLength = -1;
    }
    if (this.localSearch) {
      this.filteredItems = this._filter(value, key);
      this.localSearchValue.emit(value);
    } else if (this.liveSearch) {
      this.onValueChange.emit(value);
    }
    else {
      this.filteredItems = this.items; // Show all items when search is empty
    }
  }
  /**
   *Func triggered on scroll bar go to bottom
   */
  infiniteScroll() {
    this.onScrollDropdown.emit();
  }
  private _filter(name: any, key: any) {
    const filterValue: any = name.toLowerCase();
    if(this.liveSearch) {
      this.filteredItems?.forEach((item: any) => {
        if (this.selectedItems?.some((selectedItem: any) => selectedItem.id === item.id)) {
          item.disable = true;
        } else {
          item.disable = false;
        }
        return;
      });
    }    
    return name != '' ? this.items.filter((option: any) => typeof option === 'string' ? option.toLowerCase().includes(filterValue) : option[key].toLowerCase().includes(filterValue)) : this.items;
  }
  focusOut() {
    this.onFocusOut.emit()
  }

  addValue(event:any) {
    const newValue = event.value
    this.newValue.emit(newValue)
  }
}
