import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import moment from 'moment';

@Component({
  selector: 'single-select',
  templateUrl: './single-select.component.html',
  styleUrl: './single-select.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SingleSelectComponent)
    }
  ]
})
export class SingleSelectComponent {
  @Input() items!: any[];
  @Input() placeHolder: any;
  @Input() label: any;
  @Input() readOnly: boolean = false;
  @Input() disabled: boolean = false;
  @Input() custom: boolean = false;
  @Input() control: any = new FormControl();
  @Input() required: boolean = false;
  @Input() key_name: string = 'name';
  @Input() customClass: string = '';
  @Input() labelClass: string = '';
  @Output() onValueChange = new EventEmitter<any>();
  @Output() onScrollDropdown = new EventEmitter();
  @Output() onFocusOut = new EventEmitter()
  @Input() active: any = '';
  @Input() disable_name: any = 'name';
  @Input() none: boolean = false;
  @Input() value: any = '';
  @Input() customOption: any = '';
  @Input() count: boolean = false;
  @Input() prefix_img!: string;
  is_focused_out: boolean = true;

  constructor() { }

  ngOnInit(): void {

  }

  ngOnChanges() {
  }

  onChange = (data: any) => { }
  onTouch = (_: any) => { }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(value: any) {
    this.onChange(value);
  }

  compareOptions(value: any, option: any): boolean {
    if (typeof value === 'string') {
      return value === option;
    } else if (value && typeof value === 'object') {
      return value?.id ? value?.id === option?.id : value['name'] == option['name'];
    }
    else {
      return false;
    }
  }

  onSelectionChange(event: any) {
    // Handle the selection change event here
    this.onChange(event.value);
    this.onValueChange.emit(event.value);
  }

  /**
*Func triggered on scroll bar go to bottom
*/
  infiniteScroll() {
    this.onScrollDropdown.emit();
  }

  /**Date and Time Format Example */
  // currentDate = moment();

  onSelectFocusOut(event: any) {
    this.onFocusOut.emit(event)
  }

  focus() {
    this.is_focused_out = false;
  }

  blur() {
    this.is_focused_out = true;
  }
}
