<mat-form-field class="full-width auto_complete {{chipClass}}" appearance="outline">
    <mat-label>{{ label }}</mat-label>
  
  <div class="d-flex gap-5 align-items-center flex-wrap">
    <mat-chip-grid #chipGrid [required]="required" [formControl]="control" class="chips-evaluator">
      <div class="d-flex gap-5 flex-wrap">
       <mat-chip-row  *ngFor="let item of selectedItems;let i =index" (removed)="remove(item)" [value]="item">
           <!-- <img class="evaluator-logo" [src]=" item.badge_url ?   item?.badge_url : item?.profile_url">  -->
           <div class="chips-text"> {{ item.first_name ? item?.first_name + " "+ item?.last_name : item?.name ?? item}} </div>
           <button matChipRemove [attr.aria-label]="'remove ' + item">
             <!-- <icons [iconClass]="'icon-13'" [customClass]="'blank-icon'" [name]="'chip-close'"></icons> -->
              <img src="assets/icons/chip-close.svg" alt="">
           </button>
         </mat-chip-row>
        
      </div> 
   </mat-chip-grid>
   <input #input class="{{inputClass}} mt-2" matInput placeholder="{{placeHolder}}" [matChipInputFor]="chipGrid" [matAutocomplete]="autoEmployee"
   [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="addValue($event)"  (input)="onSearch($event.target,key_name)" (focusout)="focusOut()" multiple />
  </div>
  
    <mat-autocomplete class="drive-scroller overflow-hidden" #autoEmployee="matAutocomplete"
      (optionSelected)="selected($event,input.value)">
      <div class="dropdown_infinite_scroller">
        <ng-container *ngIf="showDropdown">
          <mat-option class="font-secondary" *ngFor="let item of filteredItems; let i = index" [value]="item" [disabled]="item?.disable">
                    {{ item.first_name ? item?.first_name + " "+ item?.last_name : item?.name ?? item }}
              <div class="option-selected" *ngIf="item.disable">
                <icons [type]="'mat-icon'" [name]="'done'"></icons>
              </div>
            <!-- </div> -->
          </mat-option>
          <mat-option *ngIf="filteredItems?.length == 0 && searchLength >= 1" class="pointer-none">
            <div class="applicable-note px-3 py-2 h-auto"><span
                  class="subtitle pal-10 font-weight-5 pab-4">Not Found!</span>
            </div>
          </mat-option>
        </ng-container>
      </div>
    </mat-autocomplete>
  
  
    <mat-error *ngIf="control?.touched && control?.hasError('required')">This field is required</mat-error>
    <mat-error *ngIf="control?.hasError('customFieldValidationError')">{{
      control.errors?.['customFieldValidationError']}}</mat-error>
    <mat-error *ngIf="control?.hasError('customError')">{{ control.errors?.['customError'] }}</mat-error>
  
  </mat-form-field>
  