import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-confirmation-popup-component',
  templateUrl: './confirmation-popup-component.component.html',
  styleUrl: './confirmation-popup-component.component.scss'
})
export class ConfirmationPopupComponentComponent {

  constructor(
    public dialogRef: MatDialogRef<ConfirmationPopupComponentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _router: Router
  ) {}

  ngOnInit(){}

  cancelPopup() {
    this.dialogRef.close(false);
    this._router.navigate([''])
  }

  confirmPopup() {
    this.dialogRef.close(true);
  }

}



