<ng-container [ngSwitch]="type">
    <!-- default icon -->
    <ng-container *ngSwitchCase="'default'">
      <div class="{{ customClass }}">
        <img class="custom-icon {{ iconClass}}" [class.active]="active" src="assets/images/icons/{{ name }}.svg" >
      </div>
    </ng-container>
    <!-- grouped icon -->
    <ng-container *ngSwitchCase="'group'">
       <div class="wz-icon-group d-flex " class="{{ customClass }}">
        <ng-container *ngFor="let icon of icons;index as i">
          <img src="assets/images/icons/{{ icon }}.svg" class="custom-icon {{ iconClass }} {{ 'button-icon-' + i }}" (click)="iconClick(icon)">
        </ng-container>
      </div>
    </ng-container>
    <!-- mat-icon -->
    <ng-container *ngSwitchCase="'mat-icon'">
      <div class="wz-icon" class="{{ customClass }}">
        <mat-icon class="custom-icon {{ iconClass }}">{{ name }}</mat-icon>
      </div>
    </ng-container>
    <!-- font-awesome icon -->
    <ng-container *ngSwitchCase="'font-awesome'">
      <div class="wz-icon" class="{{ customClass }}">
        <i class="{{ name }} custom-icon {{ iconClass }}"></i>
      </div>
    </ng-container>
  
  </ng-container>