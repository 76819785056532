import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'icons',
  templateUrl: './icons.component.html',
  styleUrl: './icons.component.scss'
})
export class IconsComponent {
  @Input() icons: string[] = []
  @Input() name: any = '';
  @Input() customClass: string = 'wz-icon';
  @Input() iconClass: string = 'icon-24';
  @Input() type: string = 'default';
  @Input() active: boolean = false;
  @Output() iconName: EventEmitter<any> = new EventEmitter;
  iconClick(name:any){
    this.iconName.emit(name)
  }
}
