<ng-container *ngIf="custom;else form">
  <mat-form-field appearance="outline" class="full-width box-input single-select {{ customClass }}">
    <mat-label *ngIf="label" class="{{ labelClass }}">{{ label }}</mat-label>
    <mat-select (selectionChange)="onSelectionChange($event)" (focusout)="onSelectFocusOut($event)"
      [formControl]="control" [placeholder]="placeHolder" [required]="required">
      <ng-content select="mat-option"></ng-content>
      <mat-select-trigger>
        <ng-content select="[mat-select-trigger]"></ng-content>
      </mat-select-trigger>
    </mat-select>
    <mat-error *ngIf="(control?.touched) && control?.hasError('required')">This field is required</mat-error>
    <mat-error *ngIf="control?.hasError('customFieldValidationError') && !control?.hasError('required')"> {{
      control?.errors?.['customFieldValidationError'] }}</mat-error>
    <mat-error *ngIf="control?.hasError('customError')"> {{ control?.errors?.['customError'] }}</mat-error>
  </mat-form-field>
</ng-container>

<ng-template #form>
  <mat-form-field appearance="outline" class="full-width box-input single-select {{ customClass }}"[ngClass]="{'prefix-field': prefix_img}">
      <mat-label *ngIf="label" class="{{ labelClass }}" >{{ label }}</mat-label>
    <mat-select (selectionChange)="onSelectionChange($event)" (focusout)="onSelectFocusOut($event)"
      [formControl]="control" [compareWith]="compareOptions" [placeholder]="placeHolder" [required]="required" [disabled]="disabled" (focus)="focus()" (blur)="blur()">
      <ng-container *ngIf="items?.length === 0 || !items">
        <mat-option class="w-100 bg-white pointer-none">
          <!-- <app-no-data-found></app-no-data-found> -->
        </mat-option>
      </ng-container>
      <mat-option *ngIf="none" [value]="customOption">{{customOption?.name ? customOption.name : customOption
        }}</mat-option>
        <!-- infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
        (scrolled)="infiniteScroll()" [scrollWindow]="false" -->
      <div class="dropdown_infinite_scroller">
        <mat-option *ngFor="let item of items" [value]="item" [disabled]="item[disable_name] === active">
          {{ item[key_name] ? item[key_name] : item }}
        </mat-option>
      </div>

    </mat-select>

  <div class="form-prefix-img" matPrefix *ngIf="prefix_img">
      <img class="w-100 h-100" [src]="prefix_img">
  </div>
    <mat-error *ngIf="(control?.touched) && control?.hasError('required')">This field is required</mat-error>
    <mat-error *ngIf="control?.hasError('customFieldValidationError') && !control?.hasError('required')"> {{
      control?.errors?.['customFieldValidationError'] }}</mat-error>
    <mat-error *ngIf="control?.hasError('customError')"> {{ control?.errors?.['customError'] }}</mat-error>

  </mat-form-field>
</ng-template>