import { Component, EventEmitter, Inject, Input, OnInit, Output, SimpleChanges, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS,MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { MatCalendarCellClassFunction } from '@angular/material/datepicker';
import { UtilService } from '../../../../core/services/util.service';


@Component({
  selector: 'date-picker',
  templateUrl: './date-picker.component.html',
  styleUrl: './date-picker.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DatePickerComponent)
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ]
})
export class DatePickerComponent {
  constructor(private _util: UtilService,
    private _adapter: DateAdapter<any>,
    @Inject(MAT_DATE_LOCALE) private _locale: string,
    ) { }
  @Input() minDate: any;
  @Input() maxDate!: any;
  @Input() placeHolder!: string;
  @Input() customClass!: string;
  @Input() format: any = 'en-GB';
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() readonly: boolean = true;
  @Input() pickerDisabled: boolean = true;
  @Input() label: any;
  @Input() control: any;
  @Input() holidays: any[] = [];
  @Input() weekends: any[] = [];
  @Input() maxError!: any;
  @Input() minError!: any;
  @Input() dateSelector: boolean = true;
  @Input() disabledClass:any = 'datePicker-disable';
  @Output() onValueChange = new EventEmitter();
  @Output() viewChanged = new EventEmitter();
  
  dateClass: MatCalendarCellClassFunction<Date> = (cellDate, view) => {
    if((new Date(cellDate).getDate() === 1 || new Date(this.weekends[0]).getFullYear() !== new Date(cellDate).getFullYear()) && view === 'month') this.catchTheDates(cellDate)
    const date = new Date(cellDate).getDate();
    const month = new Date(cellDate).getMonth()
    const year = new Date(cellDate).getFullYear()
    return this.holidays && this.holidays.some(_date => new Date(_date).getDate() === date && new Date(_date).getMonth() === month && new Date(_date).getFullYear() === year) && view === 'month' ? 'date-is-holiday' : '';
  };

  weekendFilter = (cellDate: any): boolean => {
    const date = new Date(cellDate).getDate();
    const month = new Date(cellDate).getMonth()
    const year = new Date(cellDate).getFullYear()
    return this.weekends && !this.weekends.some(_date => new Date(_date).getDate() === date && new Date(_date).getMonth() === month && new Date(_date).getFullYear() === year);
  };

  catchTheDates(date: any) {
    const startDate = new Date(date)
    const currentMonth = startDate.getMonth()
    const currentYear = startDate.getFullYear()
    this.viewChanged.emit({start: new Date(currentYear, 1, 1), end: new Date(currentYear, 12, 31)})
  }

  ngOnInit(): void {
    if(this.format === 'month') {
      this._locale = 'ja-JP'
      this._adapter.setLocale(this._locale)
    }else {
      this._locale = 'en-GB'
      this._adapter.setLocale(this._locale)
    }
  }
  
  onChange = (data:any) => {
  }
  onTouch = (touched:any) => {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(value: string) {
    this.onChange(value);
  }

  /**
  *Func triggered on input change
  */
  onchange(event: any) {
    this.onChange(this._util.getFormattedDate(event.target.value, 'YYYY-MM-DD'));
    this.onValueChange.emit(this._util.getFormattedDate(event.target.value, 'YYYY-MM-DD'));
  }
}
