import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextBoxComponent } from './text-box/text-box.component';
import { SingleSelectComponent } from './single-select/single-select.component';
import { StepperComponent } from './stepper/stepper.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { MaterialModule } from '../material/material.module';
import { PhoneComponent } from './phone/phone.component';
import { FileuploderComponent } from './fileuploder/fileuploder.component';
import { PackagesModule } from '../packages/packages.module';
import { MultiselectComponent } from './multiselect/multiselect.component';
import { IconsComponent } from './icons/icons.component';
import { SnackNotificationComponent } from './snack-notification/snack-notification.component';
import { FilePreviewSliderComponent } from './file-preview-slider/file-preview-slider.component';
import { FilePreviewPopupComponent } from './file-preview-popup/file-preview-popup.component';
import { ConfirmationPopupComponentComponent } from './confirmation-popup-component/confirmation-popup-component.component';


const components =[
  TextBoxComponent,
  SingleSelectComponent,
  StepperComponent,
  DatePickerComponent,
  PhoneComponent,
  FileuploderComponent,
  MultiselectComponent,
  IconsComponent,
  SnackNotificationComponent,
  FilePreviewSliderComponent,
  FilePreviewPopupComponent,


]
@NgModule({
  declarations: [
    ...components,
    ConfirmationPopupComponentComponent,
  
  
  
  ],
  imports: [
    CommonModule,
    MaterialModule,
    PackagesModule
  ],
  exports: [
    ...components
  ],
})
export class ReusableComponentsModule { }
